import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import './../../../index.scss';
import './History.scss';
import * as nodesRequest from '../../../api/nodesRequest';
import { formatDate, DEVICE_MANAGEMENT } from '../../../utils';
import { useSelector } from 'react-redux';
import store from '../../../redux/store/store';
import { updateDeviceHistory } from '../../../redux/actions/actions';
import DataTable from '../../DataTable/DataTable';
import blueCheck from '../../../assets/icons/blue_check.svg';
import redMinus from '../../../assets/icons/red_minus.svg';
import greenPlus from '../../../assets/icons/green_plus.svg';

const History = () => {
	const { t } = useTranslation();
	let redux = useSelector((state) => state);

	const initialState = {
		headers: [],
		historyData: [],
	};
	const [form, setState] = useReducer((state, newState) => ({ ...state, ...newState }), initialState);

	useEffect(() => {
		updateHistory();
		setState({
			headers: getTableHeaders(),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (redux.updateDeviceHistory) {
			updateHistory();
			store.dispatch(updateDeviceHistory(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [redux.updateDeviceHistory]);

	const getTableHeaders = () => {
		return [
			{
				Header: t('date_time'),
				accessor: 'date_time',
			},
			{
				Header: t('device_eui'),
				accessor: 'device_eui',
			},
			{
				Header: t('customer'),
				accessor: 'customer',
				Cell: checkCustomer,
			},
			{
				Header: t('device_type'),
				accessor: 'device_type',
				Cell: checkDeviceType,
			},
			{
				Header: t('user'),
				accessor: 'user',
			},
			{
				Header: 'Device Assignment Status',
				accessor: 'assignment_status',
			},
			{
				Header: 'Device Change',
				accessor: 'device_change',
			},
		];
	};

	const updateHistory = async () => {
		const events = await nodesRequest.fetchDeviceAssignment('ASSIGNMENT');
		let eventResponse = [];
		events.forEach((event) => {
			eventResponse.push({
				date_time: formatDate(event.createdAt),
				device_eui: event.nodeEUI,
				customer: event.customerName,
				device_type: event.deviceType,
				user: event.user,
				assignment_status: event.deviceAssignmentStatus,
				device_change:event.deviceTypeChanged,
			});
		});
		setState({
			historyData: eventResponse,
		});
	};

	const checkCustomer = (data) => {
		if (data.row.values.assignment_status === DEVICE_MANAGEMENT.ON_BOARD) {
			return (
				<div className='d-flex align-items-center device__history__column'>
					<img src={greenPlus} alt='Installed'/>
					<p>{data.row.values.customer}</p>
				</div>
			);
		} else if (data.row.values.assignment_status === DEVICE_MANAGEMENT.OFF_BOARD) {
			return (
				<div className='d-flex align-items-center device__history__column'>
					<img src={redMinus} alt='Returned'/>
					<p>{data.row.values.customer}</p>
				</div>
			);
		} else {
			return (
				<div className='d-flex align-items-center device__history__column'>
					<p>{data.row.values.customer}</p>
				</div>
			);
		}
	};

	const checkDeviceType = (data) => {
		if (data.row.values.device_change) {
			return (
				<div className='d-flex align-items-center device__history__column'>
					<img src={blueCheck} alt='Modified' />
					<p>{data.row.values.device_type}</p>
				</div>
			);
		} else {
			return (
				<div className='d-flex align-items-center device__history__column'>
					<p>{data.row.values.device_type}</p>
				</div>
			);
		}
	};

	return (
		<>
			<div className='device__history'>
				<p className='section__heading'>{t('on_off_boarding_history')}</p>
				<div className='table__indicator d-flex'>
					<div className='table__indicator__labels d-flex align-items-center'>
						<img src={greenPlus} alt='Installed'/>
						<p>Installed</p>
					</div>
					<div className='table__indicator__labels d-flex align-items-center'>
						<img src={redMinus} alt='Returned'/>
						<p>Returned</p>
					</div>
					<div className='table__indicator__labels d-flex align-items-center'>
						<img src={blueCheck} alt='Modified'/>
						<p>Modified</p>
					</div>
				</div>
			</div>
			<DataTable columns={ form.headers } data={ form.historyData }/>
		</>
	);
};

export default History;
