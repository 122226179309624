import React from 'react';
import { GoogleLogout } from 'react-google-login';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { WATERBIT_SESSION, WATERBIT_TOKEN, WATERBIT_AVATAR, WATERBIT_NAME } from '../../../api/cookieValues';
import logOut from '../../../assets/icons/log_out.svg';

const Logout = () => {
	const { t } = useTranslation();

	const logout = () => {
		if (window.gapi) {
			const auth2 = window.gapi.auth2.getAuthInstance();
			if (auth2 !== null) {
				auth2.signOut().then(auth2.disconnect().then(window.location.href = '/'));
				Cookies.remove(WATERBIT_SESSION);
				Cookies.remove(WATERBIT_TOKEN);
				Cookies.remove(WATERBIT_AVATAR);
				Cookies.remove(WATERBIT_NAME);
			}
		}
	};

	return (
		<GoogleLogout
			clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}
			render={() => {
				return (
					<button type='button' className='header__user__nav__logout__button d-flex align-items-center' onClick={logout}>
						{/iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ?
							t('logout')
							:
							<img src={logOut} alt={Cookies.get(WATERBIT_NAME)} title={t('logout')} />
						}
					</button>
				);
			}}
		/>
	);
};

export default Logout;