import React, { useEffect, useReducer } from 'react';
import Header from '../../components/Header/Header';
import './SubBand.scss';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import '../../index.scss';
import * as nodesRequest from '../../api/nodesRequest';
import Select from 'react-select';
import { RadioGroup, Radio } from 'react-radio-group';

const SubBand = (props) => {
	const { t } = useTranslation();
	const initialState = {
		selectedBand: '1',
		selectedNodes: [],
		nodes: [],
	};
	const [form, setState] = useReducer((state, newState) => ({ ...state, ...newState }), initialState);

	useEffect(() => {
		const fetchData = async () => {
			let nodesList = [];
			const response = await nodesRequest.getAllNodes();
			response.forEach(({ nodeId, customer }) => {
				nodesList.push({
					label: nodeId,
					value: nodeId,
					customer: customer,
				});
			});
			setState({ nodes: nodesList });
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const submitForm = (e) => {
		e.preventDefault();
		nodesRequest.setSubband(form.selectedBand, form.selectedNodes);
	};

	const setSelectedNodes = (nodes) => {
		let selectedNodes = [];
		nodes.forEach((node) => {
			selectedNodes.push(node.value);
		});
		setState({ selectedNodes: selectedNodes });
	};


	return (
		<div className='subBand__layout'>
			<Header header={props}/>
			<main>
				<div className='container-fluid'>
					<div className='input__form'>
						<Form className='form__subband' onSubmit={submitForm}>
							<p className='section__heading'>{t('carbon_subband')}</p>
							<Form.Group controlId='deviceEUI'>
								<Form.Label className='input__label'>{t('device_eui')}</Form.Label>
								<Select
									isMulti
									closeMenuOnSelect={false}
									options={form.nodes}
									onChange={(value) => setSelectedNodes(value || [])}
									components={{
										IndicatorSeparator: () => null,
										DropdownIndicator: () => null,
									}}
									placeholder={t('enter_eui')}
									classNamePrefix='subband__form__input'
								/>
							</Form.Group>
							<Form.Label className='input__label'>{t('select_subband')}</Form.Label>
							<RadioGroup name='subband'>
								<Radio className='options'
									value='1'
									onChange={() => setState({ selectedBand: 1 })}
									defaultChecked/>1
								<Radio className='options' value='2' onChange={() => setState({ selectedBand: 2 })}/>2
								<Radio className='options' value='3' onChange={() => setState({ selectedBand: 3 })}/>3
								<Radio className='options' value='4' onChange={() => setState({ selectedBand: 4 })}/>4
							</RadioGroup>
							<button type='submit' className='submit' size='lg'>
								{t('set')}
							</button>
						</Form>
					</div>
				</div>
			</main>
		</div>
	);
};

export default SubBand;
