/* eslint-disable camelcase */
export const OLD_NODES = 'OLD_NODES';
export const ERROR_CUSTOMER = 'ERROR_CUSTOMER';
export const ERROR_OLD_NODE = 'ERROR_OLD_NODE';
export const ERROR_NEW_NODE = 'ERROR_NEW_NODE';
export const SWAP_REQUEST_ERROR = 'SWAP_REQUEST_ERROR';
export const SWAP_REQUEST_SUCCESS = 'SWAP_REQUEST_SUCCESS';
export const SWAP_LIST_UPDATED = 'SWAP_LIST_UPDATED';
export const UPDATE_DEVICE_HISTORY = 'UPDATE_DEVICE_HISTORY';

export function oldNodes(oldNodes) {
	return {
		type: OLD_NODES,
		oldNodes,
	};
}

export function errorCustomer(error_customer) {
	return {
		type: ERROR_CUSTOMER,
		error_customer,
	};
}

export function errorOldNode(error_oldNode) {
	return {
		type: ERROR_OLD_NODE,
		error_oldNode,
	};
}

export function errorNewNode(error_newNode) {
	return {
		type: ERROR_NEW_NODE,
		error_newNode,
	};
}

export function errorSwapRequest(error_swapRequest) {
	return {
		type: SWAP_REQUEST_ERROR,
		error_swapRequest,
	};
}

export function successSwapRequest(success_swapRequest) {
	return {
		type: SWAP_REQUEST_SUCCESS,
		success_swapRequest,
	};
}

export function swapListUpdated(swapListUpdated) {
	return {
		type: SWAP_LIST_UPDATED,
		swapListUpdated,
	};
}

export function updateDeviceHistory(updateDeviceHistory) {
	return {
		type: UPDATE_DEVICE_HISTORY,
		updateDeviceHistory,
	};
}