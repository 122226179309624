import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import BurgerMenu from './BurgerMenu/BurgerMenu';
import Logout from './Logout/Logout';
import { WATERBIT_AVATAR, WATERBIT_NAME } from '../../api/cookieValues';
import wbLogo from '../../assets/img/header_logo.svg';
import hamburgerOpen from '../../assets/icons/hamburger.svg';
import hamburgerClose from '../../assets/icons/hamburger_close.svg';
import './Header.scss';
import packageJSON from '../../../package.json';

const Header = () => {
	const { t } = useTranslation();

	const [burgerIsOpen, setOpenBurger] = useState(false);

	const openBurger = () => {
		if (burgerIsOpen) {
			setOpenBurger(false);
			document.body.style.overflow = 'visible';
		} else {
			setOpenBurger(true);
			document.body.style.overflow = 'hidden';
		}
	};

	return (
		<header style={burgerIsOpen ? { height: 100 + 'vh' } : null}>
			<BurgerMenu burgerIsOpen={burgerIsOpen} />
			<nav className='navbar-expand-lg header__links d-flex justify-content-between align-items-center'>
				<div className='navbar-toggler'>
					<button type='button' onClick={openBurger}>
						<img src={burgerIsOpen ? hamburgerClose : hamburgerOpen} alt='hamburger menu' />
					</button>
				</div>
				<div className='header__logo d-flex justify-content-center align-items-center'>
					<img src={wbLogo} alt='WaterBit_Logo' />
					<span>{t('node_admin_tool')} {packageJSON.version}</span>
				</div>
				<div className='collapse navbar-collapse'>
					<ul className='navbar-nav mr-auto'>
						<li className='nav-item header__links__route'>
							<NavLink exact to='/dashboard' activeclassname='active'>{t('nav.node_swap')}</NavLink>
						</li>
						<li className='nav-item header__links__route'>
							<NavLink exact to='/subband' activeclassname='active'>{t('nav.subband')}</NavLink>
						</li>
						<li className='nav-item header__links__route'>
							<NavLink exact to='/device-management' activeclassname='active'>{t('nav.device_management')}</NavLink>
						</li>
					</ul>
					<div className='header__user__nav d-flex justify-content-end'>
						<div className='header__user__nav__logout d-flex align-items-center'>
							<img id='header__user__avatar' src={Cookies.get(WATERBIT_AVATAR)} alt={Cookies.get(WATERBIT_NAME)} />
							<span>{Cookies.get(WATERBIT_NAME)}</span>
							<Logout />
						</div>
					</div>
				</div>
			</nav>

		</header>
	);
};

export default Header;