/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy, usePagination, useGlobalFilter } from 'react-table';
import { Table } from 'react-bootstrap';
import './DataTable.scss';

// Global filter component which searches all the records present in client
const GlobalFilter = ({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
}) => {
	const count = preGlobalFilteredRows.length;

	return (
		<span className='data__table__search'>
			<p>Search:{' '}</p>
			<input
				value={globalFilter || ''}
				onChange={(e) => {
					setGlobalFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
				}}
				placeholder={`${count} records...`}
			/>
		</span>
	);
};

// Pagination component
const Pagination = ({
	canPreviousPage,
	canNextPage,
	pageOptions,
	pageCount,
	gotoPage,
	nextPage,
	previousPage,
	setPageSize,
	pageIndex,
	pageSize,
}) => {
	const rowOptions = [10, 20, 30, 40, 50];

	return (
		<div className='pagination'>
			<div className='pagination__container'>
				<div className='pagination__container__items__per__page d-flex'>
					<span>Items per page: </span>
					<select
						value={pageSize}
						onChange={(e) => {
							setPageSize(Number(e.target.value));
						}}
					>
						{rowOptions.map((pageSize) => (
							<option key={pageSize} value={pageSize}>
								{pageSize}
							</option>
						))}
					</select>
				</div>
				<div className='pagination__container__page__selection d-flex'>
					<div>
						<span>
							Page{' '}
							<strong>
								{pageIndex + 1} of {pageOptions.length}
							</strong>{' '}
						</span>
					</div>
					<div>
						<button type='button' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
							{'|<'}
						</button>
						<button type='button' onClick={() => previousPage()} disabled={!canPreviousPage}>
							{'<'}
						</button>
						<button type='button' onClick={() => nextPage()} disabled={!canNextPage}>
							{'>'}
						</button>
						<button type='button' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
							{'>|'}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};


const DataTable = (props) => {
	const {
		showSorter,
		showPagination,
		ShowGlobalSearchBox,
		columns,
		data,
	} = props;
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		page,
		prepareRow,
		preGlobalFilteredRows,
		setGlobalFilter,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		state,
	} = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: ['assignment_status', 'device_change'],
			},
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
	);

	return (
		<div className='data__table'>
			<GlobalFilter
				preGlobalFilteredRows={preGlobalFilteredRows}
				globalFilter={state.globalFilter}
				setGlobalFilter={setGlobalFilter}
			/>
			<div className='tableWrap'>
				<Table {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup, index) => (
							<tr key={index} {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, index) => (
									<th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
										<p className={column.id}>{column.render('Header')}</p>
										<span>
											{column.isSorted
												? column.isSortedDesc
													? ' 🔽'
													: ' 🔼'
												: ''}
										</span>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map(
							(row, index) => {
								prepareRow(row);
								return (
									<tr key={index} {...row.getRowProps()}>
										{row.cells.map((cell, index) => {
											return (
												<td key={index} {...cell.getCellProps({
													className: cell.column.collapse ? 'collapse' : '',
												})}>{cell.render('Cell')}</td>
											);
										})}
									</tr>
								);
							},
						)}
					</tbody>
				</Table>
			</div>
			<Pagination
				canPreviousPage={canPreviousPage}
				canNextPage={canNextPage}
				pageOptions={pageOptions}
				pageCount={pageCount}
				gotoPage={gotoPage}
				nextPage={nextPage}
				previousPage={previousPage}
				setPageSize={setPageSize}
				pageIndex={state.pageIndex}
				pageSize={state.pageSize}
			/>
		</div>
	);

};

DataTable.propTypes = {
	showPagination: PropTypes.bool,
	showGlobalSearchBox: PropTypes.bool,
	showSorter: PropTypes.bool,
	columns: PropTypes.array,
	data: PropTypes.array,
};

DataTable.defaultProps = {
	showPagination: true,
	showGlobalSearchBox: true,
	showSorter: true,
	columns: [],
	data: [],
};

DataTable.displayName = 'Data Table';

export default DataTable;
