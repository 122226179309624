import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n
	.use(initReactI18next) // Passes i18n down to react-i18next
	.use(Backend)
	.init({
		backend: {
			loadPath: './locales/{{lng}}/{{ns}}.json',
		},
		lng: 'en',
		fallbackLng: 'en',
		debug: false,
		returnObjects: true,
		interpolation: {
			escapeValue: false, // React already safes from xss
		},
	});
export default i18n;