/* eslint-disable camelcase */
import {
	OLD_NODES,
	ERROR_CUSTOMER,
	ERROR_OLD_NODE,
	ERROR_NEW_NODE,
	SWAP_REQUEST_ERROR,
	SWAP_REQUEST_SUCCESS,
	SWAP_LIST_UPDATED,
	UPDATE_DEVICE_HISTORY,
} from '../actions/actions';

const initialState = {
	errorCustomer: [],
	errorOldNode: [],
	errorNewNode: [],
	errorSwapRequest: [],
	successSwapRequest: [],
	swapListUpdated: false,
	updateDeviceHistory: false,
};

function rootReducer(state = initialState, action) {
	switch (action.type) {
	case OLD_NODES:
		return {
			...state,
			oldNodes: action.oldNodes,
		};
	case ERROR_CUSTOMER:
		return {
			...state,
			errorCustomer: action.error_customer,
		};
	case ERROR_OLD_NODE:
		return {
			...state,
			errorOldNode: action.error_oldNode,
		};
	case ERROR_NEW_NODE:
		return {
			...state,
			errorNewNode: action.error_newNode,
		};
	case SWAP_REQUEST_ERROR:
		return {
			...state,
			errorSwapRequest: action.error_swapRequest,
		};
	case SWAP_REQUEST_SUCCESS:
		return {
			...state,
			successSwapRequest: action.success_swapRequest,
		};
	case SWAP_LIST_UPDATED:
		return {
			...state,
			swapListUpdated: action.swapListUpdated,
		};
	case UPDATE_DEVICE_HISTORY:
		return {
			...state,
			updateDeviceHistory: action.updateDeviceHistory,
		};
	default:
		return state;
	}
}

export default rootReducer;
