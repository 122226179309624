import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row } from 'react-bootstrap';
import Header from '../../components/Header/Header';
import NodeSwap from '../../components/NodeSwap/NodeSwap';
import NodeQueue from '../../components/NodeQueue/NodeQueue';
import SwapLayout from '../../components/SwapLayout/SwapLayout';
import './Dashboard.scss';

const Dashboard = (props) => {
	const { t } = useTranslation();

	return (
		<div className='dashboard__layout'>
			<Header header={props} />
			<main>
				<Container fluid={true}>
					<Row>
						<div id='node__swap__component'>
							<SwapLayout name={t('node_swap')}>
								<NodeSwap />
							</SwapLayout>
						</div>
						<div id='node__queue__component'>
							<SwapLayout name={t('node_queue')}>
								<NodeQueue />
							</SwapLayout>
						</div>
					</Row>
				</Container>
			</main>
		</div>
	);
};

export default Dashboard;
