import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logout from '../Logout/Logout';

const BurgerMenu = ({ burgerIsOpen }) => {
	const { t } = useTranslation();

	return (
		<div className={`header__hamburger w-100 ${ burgerIsOpen ? 'open' : '' }`}>
			<div className='header__hamburger__links d-flex justify-content-center align-items-center flex-column w-100'>
				<ul>
					<li>
						<NavLink to='/dashboard' activeclassname='active'>
							{t('nav.node_swap')}
						</NavLink>
					</li>
					<li>
						<NavLink to='/subband' activeclassname='active'>
							{t('nav.subband')}
						</NavLink>
					</li>
					<li>
						<NavLink to='/device-management' activeclassname='active'>
							{t('nav.device_management')}
						</NavLink>
					</li>
				</ul>
				<Logout />
			</div>
		</div>
	);
};

BurgerMenu.propTypes = {
	burgerIsOpen: PropTypes.bool,
};

export default BurgerMenu;