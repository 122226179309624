import React from 'react';
import PropTypes from 'prop-types';
import './SwapLayout.scss';

const SwapLayout = ({ name, children }) => {
	return (
		<div className='swap__layout'>
			<span className='swap__layout__title'>{name}</span>
			<div className='swap__layout__node__swap'>{children}</div>
		</div>
	);
};

SwapLayout.propTypes = {
	name: PropTypes.string.isRequired,
	children: PropTypes.object.isRequired,
};

export default SwapLayout;