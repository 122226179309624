import React from 'react';
import Header from '../../components/Header/Header';
import './DeviceManager.scss';
import DeviceForm from '../../components/DeviceManager/Form/Form';
import History from '../../components/DeviceManager/History/History';

const DeviceManager = (props) => {
	return (
		<div className='device__manager__layout'>
			<Header header={props}/>
			<main>
				<div className='container-fluid'>
					<div className='main__content'>
						<div className='input__form'>
							<DeviceForm/>
						</div>
						<div className='history__queue'>
							<History/>
						</div>
					</div>
				</div>
			</main>
		</div>
	);
};

export default DeviceManager;
