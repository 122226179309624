/* eslint-disable no-console */
import Cookies from 'js-cookie';
import i18n from '../i18n/i18n';
import { WATERBIT_TOKEN } from '../api/cookieValues';
import { CUSTOMERS, DEVICE_MANAGER, NODES, SUBBAND_URL, PG_CUSTOMERS, PG_NODES, SWAP_URL, DEVICE_TYPE } from './endpoints';
import store from '../redux/store/store';
import {
	errorCustomer,
	errorNewNode,
	errorOldNode,
	errorSwapRequest,
	successSwapRequest,
	swapListUpdated,
} from '../redux/actions/actions';

const UNASSIGNED_CUSTOMER = 'Unassigned Customer';

let customers = [];
let unassignedNodes = {};
let allNodes = {};
let customerNodes = {};

// New response variables
let pgCustomers = {};
let pgAllNodes = {};
let pgUnassignedNodes = {};
let pgCustomerNodes = {};

// Check if the token has expired
export const accessTokenHasExpired = () => {
	if (!Cookies.get(WATERBIT_TOKEN)) {
		alert(i18n.t('token_expired'));
		window.location.href = '/';
		return true;
	}
	return false;
};

// TODO - Once postgres data is stable, need to remove these call from mysql.
// Get the list of customers
export const getCustomersList = async () => {

	if (accessTokenHasExpired()) return;

	try {
		let res = await fetch(CUSTOMERS, {
			headers: {
				'accessToken': Cookies.get(WATERBIT_TOKEN),
			},
		});
		const json = await res.json();
		json.map((value) => {
			return customers.push({
				label: value.customerName,
				value: value.customerName,
			});
		});
		return json;
	} catch (error) {
		throw new Error(error);
	}
};

// Returns list of all nodes in system
export const getAllNodes = async () => {
	allNodes = await getNodes(NODES);
	return allNodes;
};

/**
 * Returns list of nodes for the selected customer
 * @param {String} customerName - Selected customer
 * @returns Nodes object
 */
export const getCustomerNodes = async (customerName) => {
	customerNodes = await getNodes(`${NODES}?customerName=${customerName}`);
	return customerNodes;
};

// Returns list of unassigned nodes
export const getUnassignedNodes = async () => {
	unassignedNodes = await getNodes(`${NODES}?customerName=${UNASSIGNED_CUSTOMER}`);
};

const getNodes = async (url) => {

	if (accessTokenHasExpired()) return;

	try {
		const res = await fetch(url, {
			headers: {
				accessToken: Cookies.get(WATERBIT_TOKEN),
			},
		});
		return await res.json();
	} catch (error) {
		throw new Error(error);
	}
};

// Get the list of swaps
export const getSwapList = async () => {

	if (accessTokenHasExpired()) return;

	try {
		let res = await fetch(SWAP_URL, {
			headers: {
				'accessToken': Cookies.get(WATERBIT_TOKEN),
			},
		});
		return await res.json();
	} catch (error) {
		throw new Error(error);
	}
};

/**
 * Requests a new swap
 * @param {String} customerName Customer
 * @param {String} oldNodeId OLD Node ID
 * @param {String} newNodeId NEW Node ID
 * @param {Date} timeOfSwap Time, Time Zone values
 * @returns HTTP status code
 */
export const requestSwap = async (customerName, oldNodeId, newNodeId, timeOfSwap) => {

	if (accessTokenHasExpired()) return;

	// Get the custom timestamp
	const occurredAt = new Date(timeOfSwap);

	try {
		// Send the request
		let res = await fetch(SWAP_URL, {
			method: 'POST',
			headers: {
				'accessToken': Cookies.get(WATERBIT_TOKEN),
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				customerName,
				newNodeId,
				occurredAt,
				oldNodeId,
			}),
		});

		let { status, reasonsForRejection } = await res.json();
		let serverResponse = await res.status;

		if (status === 'Invalid') {
			let customer = [];
			let oldNodes = [];
			let newNodes = [];
			reasonsForRejection.map((msg) => {
				if (msg.includes('CustomerNotFound')) {
					customer.push(i18n.t(`error.${msg}`));
					store.dispatch(errorCustomer(customer));
				} else if (/customer|OldDevice/.test(msg)) {
					oldNodes.push(i18n.t(`error.${msg}`));
					store.dispatch(errorOldNode(oldNodes));
				} else if (/customer|NewDevice/.test(msg)) {
					newNodes.push(i18n.t(`error.${msg}`));
					store.dispatch(errorNewNode(newNodes));
				} else if (msg === 'SwapAlreadyEnqueued') {
					store.dispatch(errorSwapRequest([i18n.t(`error.${msg}`)]));
				}
				return 0;
			});
			customer = [];
			oldNodes = [];
			newNodes = [];
		} else if (serverResponse === 200) {
			store.dispatch(successSwapRequest([i18n.t('swap_created_successfully')]));
			store.dispatch(swapListUpdated(true));
		}

		return serverResponse;

	} catch (error) {
		throw new Error(error);
	}
};

/**
 * Deletes the "Unstarted" swap from the table row
 * @param {String} oldNodeId OLD NODE ID
 * @param {String} newNodeId NEW NODE ID
 * @param {Date} createdAt DATE - TIME
 */
export const deleteSwap = async (oldNodeId, newNodeId, createdAt) => {

	if (accessTokenHasExpired()) return;

	try {
		let res = await fetch(`${SWAP_URL}?swapIdentifier=${oldNodeId}_${newNodeId}_${createdAt}`, {
			method: 'DELETE',
			headers: {
				'accessToken': Cookies.get(WATERBIT_TOKEN),
			},
		});

		if (res.status === 200) {
			store.dispatch(swapListUpdated(true));
		}

	} catch (error) {
		throw new Error(error);
	}
};

export const setSubband = async (selectedBand, selectedNodes) => {

	if (accessTokenHasExpired()) return;

	try {
		const response = await fetch(SUBBAND_URL, {
			method: 'POST',
			headers: {
				'accessToken': Cookies.get(WATERBIT_TOKEN),
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				subband: selectedBand,
				nodes: selectedNodes,
			}),
		});

		if (response.status === 200) {
			alert('Carbon subband updated successfully');
		} else {
			alert('Request cannot be completed. Please try again.');
		}
	} catch (error) {
		throw new Error(error);
	}
};

export const getCustomersListFromPg = async () => {

	if (accessTokenHasExpired()) return;

	try {
		const response = await fetch(PG_CUSTOMERS, {
			headers: {
				'accessToken': Cookies.get(WATERBIT_TOKEN),
			},
		});
		pgCustomers = await response.json();
	} catch (error) {
		throw new Error(error);
	}
};

export const getAllNodesFromPg = async () => {
	pgAllNodes = await getNodesFromPg(PG_NODES);
};

export const getCustomerNodesFromPg = async (externalId) => {
	pgCustomerNodes = await getNodesFromPg(`${PG_NODES}?customerExternalId=${externalId}`);
};

export const getUnassignedNodesFromPg = async () => {
	pgUnassignedNodes = await getNodesFromPg(`${PG_NODES}?unassignedOnly=true`);
};

const getNodesFromPg = async (url) => {

	if (accessTokenHasExpired()) return;

	try {
		const res = await fetch(url, {
			headers: {
				accessToken: Cookies.get(WATERBIT_TOKEN),
			},
		});
		return await res.json();
	} catch (error) {
		throw new Error(error);
	}
};

export const updateDeviceAssignment = async (nodeExternalId, customerExternalId, deviceType, status) => {

	if (accessTokenHasExpired()) return;

	try {
		const response = await fetch(DEVICE_MANAGER, {
			method: 'POST',
			headers: {
				'accessToken': Cookies.get(WATERBIT_TOKEN),
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				nodeExternalId,
				customerExternalId,
				deviceType,
				status,
			}),
		});
		return await response;
	} catch (e) {
		throw new Error(e);
	}
};

export const fetchDeviceAssignment = async (eventType) => {

	if (accessTokenHasExpired()) return;

	try {
		const res = await fetch(`${DEVICE_MANAGER}?eventType=${eventType}`, {
			headers: {
				accessToken: Cookies.get(WATERBIT_TOKEN),
			},
		});
		return await res.json();
	} catch (error) {
		throw new Error(error);
	}
};

export const fetchDeviceType = async (deviceIds) => {

	if (accessTokenHasExpired()) return;

	const url = deviceIds !== undefined
		? `${DEVICE_TYPE}?deviceIds=${deviceIds}`
		: DEVICE_TYPE;
	try {
		const res = await fetch(url, {
			headers: {
				accessToken: Cookies.get(WATERBIT_TOKEN),
			},
		});
		return await res.json();
	} catch (error) {
		throw new Error(error);
	}
};

export {
	customers,
	allNodes,
	customerNodes,
	unassignedNodes,
	pgCustomers,
	pgAllNodes,
	pgUnassignedNodes,
	pgCustomerNodes,
};
