import React, { useEffect, useReducer, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DataTable from '../DataTable/DataTable';
import { getSwapList, deleteSwap } from '../../api/nodesRequest';
import { swapListUpdated } from '../../redux/actions/actions';
import { formatDate } from '../../utils';
import store from '../../redux/store/store';
import trashCan from '../../assets/icons/trash_can.svg';
import './NodeQueue.scss';

const NodeQueue = () => {
	const { t } = useTranslation();

	let redux = useSelector((state) => state);

	// Set component state
	const [table, setState] = useReducer(
		(state, newState) => ({ ...state, ...newState }),
		{ isReady: false, nodes: [] },
	);

	const updateTableList = () => {
		getSwapList()
			.then((json) => {
				setState({
					isReady: true,
					nodes: json,
				});
			})
			.catch((error) => {
				throw new Error(error);
			});
	};

	// This will trigger as soon as the component mounts
	useEffect(() => {
		updateTableList();
	}, []);

	// This will trigger when a node is deleted from the table
	useEffect(() => {
		if (redux.swapListUpdated) {
			updateTableList();
			store.dispatch(swapListUpdated(false));
		}
	}, [redux.swapListUpdated]);

	const colorSwapStatus = ({ swapStatus }) => {
		if (swapStatus === 'Finished') {
			return <p className='finished-status'>{t('node_queue_status.Finished')}</p>;
		} else {
			return <p className='node-queue-status'>{t(`node_queue_status.${swapStatus}`)}</p>;
		}
	};

	const deleteSwapIcon = ({ oldNodeId, newNodeId, createdAt, swapStatus }) => {
		if (swapStatus === 'Unstarted') {
			return <img src={trashCan} alt='Trash can' className='node__swap__delete__swap' onClick={() => deleteSwap(oldNodeId, newNodeId, createdAt)} />;
		}
	};

	const columns = useMemo(
		() => [
			{
				Header: `${t('date_time_requested')}`,
				accessor: ({ createdAt }) => formatDate(createdAt),
				maxWidth: 5,
			},
			{
				Header: `${t('date_time_of_swap')}`,
				accessor: ({ createdAt }) => formatDate(createdAt),
				maxWidth: 5,
			},
			{
				Header: `${t('status')}`,
				accessor: colorSwapStatus,
				maxWidth: 5,
			},
			{
				Header: `${t('customer')}`,
				accessor: 'customerName',
				maxWidth: 5,
			},
			{
				Header: `${t('old_node_id')}`,
				accessor: 'oldNodeId',
				maxWidth: 5,
			},
			{
				Header: `${t('new_node_id')}`,
				accessor: 'newNodeId',
				maxWidth: 5,
			},
			{
				Header: `${t('user')}`,
				accessor: 'userEmail',
				maxWidth: 5,
			},
			{
				Header: `${t('migrated_records')}`,
				accessor: 'historicalRecordTotal',
				// eslint-disable-next-line react/display-name
				Cell: ({ value }) => <p className='migrated_records'>{value}</p>,
				maxWidth: 5,
			},
			{
				Header: `${t('actions')}`,
				accessor: deleteSwapIcon,
				maxWidth: 5,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	return table.isReady ? <DataTable data={table.nodes.reverse()} columns={columns} showPagination /> : false;
};

NodeQueue.displayName = 'NodeQueue';

export default NodeQueue;
