/* eslint-disable camelcase */
/* eslint-disable init-declarations */
import React, { useReducer, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, FormGroup, FormLabel, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import ErrorMessage from './ErrorMessage/ErrorMessage';
import {
	oldNodes,
	errorCustomer,
	errorOldNode,
	errorNewNode,
	errorSwapRequest,
	successSwapRequest,
} from '../../redux/actions/actions';
import * as nodesRequest from '../../api/nodesRequest';
import store from '../../redux/store/store';
import './NodeSwap.scss';
import 'react-datepicker/dist/react-datepicker.min.css';


const NodeSwap = () => {
	// Translation object
	const { t } = useTranslation();

	// Redux
	const redux = useSelector((state) => state);

	// Set component state
	const [input_placeholder, setState] = useReducer(
		(state, newState) => ({ ...state, ...newState }),
		{
			nodeDisabled: true,
			customerName: null,
			oldNodeId: null,
			newNodeId: null,
			customerList: [],
			oldNodeOptions: [],
			newNodeOptions: [],
			customerPlaceholder: t('unassigned_customer'),
			oldNodePlaceholder: t('enter_old_node'),
			newNodePlaceholder: t('enter_new_node'),
			dropdownMenuState: {
				customer: false,
				oldNode: false,
				newNode: false,
			},
			startDate: new Date(),
			timezone: new Intl.DateTimeFormat().resolvedOptions().timeZone,
			currentTimeZoneAbbrevation: /.*\s(.+)/.exec((new Date()).toLocaleDateString(navigator.language, { timeZoneName:'short' }))[1],
			resetInputTime: false,
			customTimeValue: '',
		},
	);

	useEffect(() => {
		const fetchData = async () => {
			const customersArray = await nodesRequest.getCustomersList();
			customersArray.forEach(({ customerName }) => {
				input_placeholder.customerList.push({
					label: customerName,
					value: customerName,
				});
			});


			const oldNodesArray = await nodesRequest.getAllNodes();
			oldNodesArray.forEach(({ nodeId, customer }) => {
				input_placeholder.oldNodeOptions.push({
					label: nodeId,
					value: nodeId,
					customer: customer,
				});

			});

			await nodesRequest.getUnassignedNodes();
			nodesRequest.unassignedNodes.forEach(({ nodeId }) => {
				input_placeholder.newNodeOptions.push({
					label: nodeId,
					value: nodeId,
				});
			});
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const appendCustomerName = async (e) => {
		removeAllValidations();

		// Update state
		setState({
			nodeDisabled: false,
			customerName: e.value,
			customerPlaceholder: e.label,
			oldNodeId: null,
		});

		// Empty the current "OLD Node ID" state
		input_placeholder.oldNodeOptions = [];

		// Get the list of nodes that belong to the selected customer
		await nodesRequest.getCustomerNodes(e.value);
		store.dispatch(oldNodes(nodesRequest.customerNodes));
		nodesRequest.customerNodes.forEach(({ nodeId, customer }) => {
			input_placeholder.oldNodeOptions.push({
				label: nodeId,
				value: nodeId,
				customer: customer,
			});
		});
	};

	const appendOldNode = (e) => {
		removeAllValidations();
		setState({
			nodeDisabled: false,
			customerName: e.customer,
			customerPlaceholder: e.customer,
			oldNodeId: e.value,
			oldNodePlaceholder: e.label,
		});
	};

	const appendNewNode = (e) => {
		removeAllValidations();
		setState({
			newNodeId: e.value,
			newNodePlaceholder: e.label,
		});
	};

	// Remove validations
	const removeCustomerValidation = () => {
		store.dispatch(errorCustomer([]));
	};

	const removeOldNodeValidation = () => {
		store.dispatch(errorOldNode([]));
	};

	const removeNewNodeValidation = () => {
		store.dispatch(errorNewNode([]));
	};

	const removeSwapRequestValidation = () => store.dispatch(errorSwapRequest([]));

	const removeAllValidations = () => {
		store.dispatch(errorCustomer([]));
		store.dispatch(errorOldNode([]));
		store.dispatch(errorNewNode([]));
		store.dispatch(errorSwapRequest([]));
		store.dispatch(successSwapRequest([]));
	};

	// Handle submit
	const handleSubmit = async (e) => {
		e.preventDefault();
		removeSwapRequestValidation();

		const { customerName, oldNodeId, newNodeId } = input_placeholder;

		const timeOfSwapWithTimeZone = new Date(input_placeholder.startDate).toLocaleString('en-US', { timeZone: input_placeholder.timezone });
		const timeOfSwap = new Date(timeOfSwapWithTimeZone).toUTCString();

		const status = await nodesRequest.requestSwap(customerName, oldNodeId, newNodeId, timeOfSwap);
		if (status === 200) {
			setState({
				oldNodeId: null,
				newNodeId: null,
				oldNodePlaceholder: t('enter_old_node'),
				newNodePlaceholder: t('enter_new_node'),
			});
		}
	};

	// Input form styles
	const inputFormStyle = {
		placeholder: (styles) => ({ ...styles, color: '#000' }),
	};

	// eslint-disable-next-line react/prop-types
	const CustomTimeInput = ({ value, onChange }) => {
		const date = new Date();
		const currentTime = (date).toTimeString().substr(0,5);

		return (
			<>
				<input
					type='time'
					value={input_placeholder.resetInputTime ? input_placeholder.customTimeValue : value}
					onChange={(e) => {
						const currentValue = e.target.value;

						const getHour = Number(currentValue.substr(0, 2));
						const getMinute = Number(currentValue.substr(3, 2));

						if (new Date(new Date(input_placeholder.startDate).setHours(getHour, getMinute)) <= new Date()) {
							onChange(currentValue);
							setState({ customTimeValue: currentValue, startDate: new Date(new Date(input_placeholder.startDate).setHours(getHour, getMinute)) });
						} else {
							onChange(currentTime);
							setState({ customTimeValue: currentTime, startDate: new Date(new Date(input_placeholder.startDate).setHours(getHour, getMinute)) });
						}
					}}
					max={`${currentTime}`}
				/>
				<span onClick={() => setState({ resetInputTime: true, customTimeValue: '' })}>Reset</span>
			</>
		);
	};

	return (
		<Form className='node__swap' onSubmit={handleSubmit}>
			<Row className='form-row'>
				<FormGroup as={Col} xl={3} xs={12}>
					<FormLabel>{t('customer')}</FormLabel>
					<Select
						options={input_placeholder.customerList}
						onChange={appendCustomerName}
						onFocus={removeCustomerValidation}
						components={{
							IndicatorSeparator: () => null,
							DropdownIndicator: () => null,
						}}
						placeholder={input_placeholder.customerPlaceholder}
						noOptionsMessage={() => t('not_found')}
						classNamePrefix={redux.errorCustomer.length >= 1 ? 'node__swap__invalid' : 'node__swap__input'}
						hideSelectedOptions
						styles={inputFormStyle}
					/>
					<ErrorMessage isHidden={redux.errorCustomer.length >= 1 ? false : true}
						message={redux.errorCustomer}/>
				</FormGroup>
				<FormGroup as={Col} xl={2} xs={12}>
					<FormLabel>{t('old_node_id_capitalized')}</FormLabel>
					<Select
						options={input_placeholder.oldNodeOptions}
						onChange={appendOldNode}
						onFocus={removeOldNodeValidation}
						onInputChange={(e) => setState({ dropdownMenuState: { oldNode: !!e } })}
						menuIsOpen={input_placeholder.dropdownMenuState.oldNode}
						openMenuOnClick={false}
						components={{
							IndicatorSeparator: () => null,
							DropdownIndicator: () => null,
						}}
						placeholder={input_placeholder.oldNodePlaceholder}
						noOptionsMessage={() => t('no_matching_id')}
						classNamePrefix={redux.errorOldNode.length >= 1 ? 'node__swap__invalid' : 'node__swap__input'}
						value={input_placeholder.oldNodePlaceholder}
						hideSelectedOptions
						styles={inputFormStyle}
					/>
					<ErrorMessage isHidden={redux.errorOldNode.length >= 1 ? false : true}
						message={redux.errorOldNode}/>
				</FormGroup>
				<FormGroup as={Col} xl={2} xs={12}>
					<FormLabel>{t('new_node_id_capitalized')}</FormLabel>
					<Select
						isDisabled={input_placeholder.nodeDisabled}
						options={input_placeholder.newNodeOptions}
						onChange={appendNewNode}
						onFocus={removeNewNodeValidation}
						onInputChange={(e) => setState({ dropdownMenuState: { newNode: !!e } })}
						menuIsOpen={input_placeholder.dropdownMenuState.newNode}
						openMenuOnClick={false}
						components={{
							IndicatorSeparator: () => null,
							DropdownIndicator: () => null,
						}}
						placeholder={input_placeholder.newNodePlaceholder}
						noOptionsMessage={() => t('no_matching_id')}
						classNamePrefix={redux.errorNewNode.length >= 1 ? 'node__swap__invalid' : 'node__swap__input'}
						value={input_placeholder.newNodePlaceholder}
						hideSelectedOptions
						styles={inputFormStyle}
					/>
					<ErrorMessage isHidden={redux.errorNewNode.length >= 1 ? false : true}
						message={redux.errorNewNode}/>
				</FormGroup>
				<FormGroup as={Col} xl={2} xs={9}>
					<FormLabel>{t('time')}</FormLabel>
					<DatePicker
						className='node__swap__datepicker'
						selected={new Date(input_placeholder.startDate) <= new Date() ? input_placeholder.startDate : new Date()}
						onChange={(date) => setState({ startDate: date, resetInputTime: new Date(date) < new Date() })}
						timeInputLabel={t('time') + ':'}
						dateFormat='MM/dd/yyyy h:mm aa'
						showTimeInput
						maxDate={new Date()}
						customTimeInput={<CustomTimeInput />}
					/>
				</FormGroup>
				<FormGroup as={Col} xl={1} xs={4}>
					<FormLabel>{t('time_zone')}</FormLabel>
					<Select
						classNamePrefix='node__swap__input'
						defaultValue={input_placeholder.currentTimeZoneAbbrevation}
						placeholder={input_placeholder.currentTimeZoneAbbrevation}
						onChange={(e) => setState({ timezone: e.value, currentTimeZoneAbbrevation: e.label })}
						options={[
							{ label: 'PST', value: 'America/Los_Angeles' },
							{ label: 'MST', value: 'America/Denver' },
							{ label: 'CST', value: 'America/Chicago' },
							{ label: 'EST', value: 'America/New_York' },
							{ label: `${new Date().toLocaleTimeString('en-us', { timeZoneName:'short' }).split(' ')[2]}`, value: `${new Intl.DateTimeFormat().resolvedOptions().timeZone}` },
						]}
						components={{
							IndicatorSeparator: () => null,
							DropdownIndicator: () => null,
						}}
						hideSelectedOptions
						styles={inputFormStyle}
					/>
				</FormGroup>
				<Col xl={2}>
					<button
						className='node__swap__submit__button'
						type='submit'
						disabled={(input_placeholder.customerName !== null) && (input_placeholder.oldNodeId !== null) && (input_placeholder.newNodeId !== null) ? false : true}>{t('request_swap')}
					</button>
					<ErrorMessage
						isHidden={redux.errorSwapRequest.length >= 1 ? false : redux.successSwapRequest.length >= 1 ? false : true}
						message={redux.errorSwapRequest.length >= 1 ? redux.errorSwapRequest : redux.successSwapRequest}
						type={redux.errorSwapRequest.length >= 1 ? 'error' : 'success'}/>
				</Col>
			</Row>
		</Form>
	);
};

NodeSwap.displayName = 'NodeSwap';

export default NodeSwap;
