import React, { Suspense } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import DeviceManager from './pages/DeviceManager/DeviceManager';
import 'bootstrap/scss/bootstrap.scss';
import SubBand from './pages/SubBand/SubBand';

const ProtectedRoute = ({ component: Component, ...rest }) => {
	let isLoggedIn = Cookies.get('WATERBIT_SESSION');
	return (
		<Route {...rest} render={(props) => {
			if (isLoggedIn === '1') {
				return <Component {...props} />;
			} else {
				return <Redirect to='/' />;
			}
		}} />
	);
};

function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Suspense fallback={<div>Loading...</div>}>
					<Route exact path='/' component={Login} />
					<ProtectedRoute exact path='/dashboard' component={Dashboard} />
					<ProtectedRoute exact path='/device-management' component={DeviceManager} />
					<ProtectedRoute exact path='/subband' component={SubBand} />
				</Suspense>
			</Switch>
		</BrowserRouter>
	);
}

ProtectedRoute.propTypes = {
	component: PropTypes.func,
};

export default App;
