import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './ErrorMessage.scss';

const Message = ({ errorMessages, type }) => {
	let li = errorMessages.map((msg, index) => {
		if (type === 'success') {
			return <li key={index} className='node__success'>{msg}</li>;
		} else {
			return (
				<li key={index} className='node__error'>{msg}</li>
			);
		}
	});
	return li;
};

const ErrorMessage = ({ isHidden, message, type }) => {
	return (
		<ul className={`node__swap__error ${isHidden ? 'node__swap__error__hide' : 'node__swap__error__show'}`}>
			<Message isHidden={isHidden} errorMessages={message} type={type} />
		</ul>
	);
};

ErrorMessage.propTypes = {
	isHidden: PropTypes.bool,
	message: PropTypes.array,
	type: PropTypes.string,
};

const mapStateToProps = (state) => {
	return { state };
};

export default connect(mapStateToProps)(ErrorMessage);