import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleLogin } from 'react-google-login';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import LoadingCog from '../../components/Loading/LoadingCog';
import { WATERBIT_SESSION, WATERBIT_TOKEN, WATERBIT_AVATAR, WATERBIT_NAME } from '../../api/cookieValues';
import { getCustomersList } from '../../api/nodesRequest';
import exclamationPoint from '../../assets/icons/exclamation_circle_red.svg';
import wbLogo from '../../assets/img/login_logo.svg';
import './Login.scss';

if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost') {
	window.location.href = 'https://' + window.location.host + window.location.hash;
}

// Check if the user has a valid cookie
if (Cookies.get(WATERBIT_SESSION) === '1') {
	if (window.location.pathname === '/') {
		window.history.pushState({}, '', '/dashboard');
	}
}

const Login = (props) => {
	// Translation object
	const { t } = useTranslation();
	// Component state
	let [showModal, setErrorMessage] = useState(false);
	let [showLoading, setLoading] = useState(false);

	const responseGoogleSuccess = async (response) => {
		// TODO
		// Node Admin UI uses "refresh token" instead of "access token"
		// https://jira.waterbit.com/browse/AP-961

		// Google object
		const { accessToken, profileObj, tokenObj } = response;

		// Cookies
		let date = new Date();
		let cookieExpiration = date.getTime() + tokenObj.expires_in * 1000;
		date.setTime(cookieExpiration);
		let UTCDate = date.toUTCString();
		Cookies.set(WATERBIT_TOKEN, accessToken, { expires: new Date(UTCDate) });
		setLoading(true);

		try {
			const customersList = await getCustomersList();

			setErrorMessage(false);

			if (customersList.length) {
				Cookies.set(WATERBIT_AVATAR, profileObj.imageUrl, { expires: new Date(UTCDate) });
				Cookies.set(WATERBIT_NAME, profileObj.name, { expires: new Date(UTCDate) });
				Cookies.set(WATERBIT_SESSION, 1, { expires: new Date(UTCDate) });

				setLoading(false);

				// Navigate to dashboard
				props.history.go('/dashboard');
			}

		} catch (error) {
			// Display login error
			setLoading(false);
			setErrorMessage(true);
			throw new Error(error);
		}

	};

	const LoginState = ({ showModal, showLoading }) => {
		if (showModal) {
			return (
				<div className={showModal ? 'login__error__modal d-flex justify-content-center align-items-center show__modal' : 'login__error__modal hide__modal' }>
					<img src={exclamationPoint} alt='excl_icon'/>
					<p>&nbsp;{t('login.fail')}:&nbsp;</p>
					<p>{t('login.insufficient_credentials')}</p>
				</div>
			);
		} else if (showLoading) {
			return <LoadingCog isVisible={true} width='2em' height='2em' containerHeight='61px' />;
		} else {
			return <div style={{ height: '61px', marginBottom: '1.5em' }}></div>;
		}
	};

	return (
		<main className='login__logo'>
			<Container>
				<div className='d-flex justify-content-center align-items-center flex-column'>
					<div className='login__waterbit__logo d-flex  justify-content-center align-items-center flex-column'>
						<img src={wbLogo} alt='Logo' />
						<span className='login__waterbit__logo__text'>{t('node_admin_tool')}</span>
					</div>
					<div className='login__logo__error'>
						<LoginState showModal={showModal} showLoading={showLoading} />
					</div>
					<div className='login__logo__btn__login'>
						<GoogleLogin
							clientId={process.env.REACT_APP_OAUTH_CLIENT_ID}
							onSuccess={responseGoogleSuccess}
							cookiePolicy={'single_host_origin'}
							buttonText={t('login.sign_in_with_google')}
						/>
					</div>
				</div>
			</Container>
		</main>
	);
};

Login.propTypes = {
	history: PropTypes.object,
	showModal: PropTypes.bool,
};

export default Login;
