// Hostname
const ENDPOINT_URL = process.env.REACT_APP_URI_ENDPOINT;

// Endpoints
const CUSTOMERS = `${ENDPOINT_URL}/customers`;
const NODES = `${ENDPOINT_URL}/nodes`;
const SWAP_URL = `${ENDPOINT_URL}/swaps`;
const PG_CUSTOMERS = `${ENDPOINT_URL}/pgCustomers`;
const PG_NODES = `${ENDPOINT_URL}/pgNodes`;
const DEVICE_MANAGER = `${ENDPOINT_URL}/deviceManagement`;
const SUBBAND_URL = `${ENDPOINT_URL}/subBands`;
const DEVICE_TYPE = `${ENDPOINT_URL}/device-types`;

export { CUSTOMERS, NODES, SWAP_URL, SUBBAND_URL, PG_CUSTOMERS, PG_NODES, DEVICE_MANAGER, DEVICE_TYPE };
