import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import { updateDeviceHistory } from '../../../redux/actions/actions';
import './Form.scss';
import * as nodesRequest from '../../../api/nodesRequest';
import store from '../../../redux/store/store';
import { DEVICE_MANAGEMENT } from '../../../utils';

const DeviceForm = () => {
	const UNASSIGNED_CUSTOMER = 'Unassigned Customer';
	const { t } = useTranslation();

	const [isInputChanged, setInputChanged] = useState(false);
	const initialState = {
		operationMsg: '',
		loadingNodes: false,
		showConfirmation: false,
		isSubmitDisabled: true,
		isCustomerDisabled: true,
		isTypeDisabled: true,
		messageClass: 'green',
		nodePlaceholder: t('enter_eui'),
		customerPlaceholder: '--',
		typePlaceholder: '--',
		nodes: [],
		customers: [],
		types: [],
		selectedNode: [],
		selectedCustomer: {},
		selectedType: {},
		filterBy: '',
		typeIndicator: '',
		typeIndicatorList: [],
	};
	const [form, setState] = useReducer((state, newState) => ({ ...state, ...newState }), initialState);

	useEffect(() => {
		populateFormControls();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isInputChanged) {
			adjustControls();
			setInputChanged(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInputChanged]);

	const populateFormControls = async () => {
		let customers = [];
		await nodesRequest.getCustomersListFromPg();
		nodesRequest.pgCustomers.forEach((customer) => {
			customers.push({
				label: customer.name,
				value: customer.externalId,
			});
		});

		let nodes = [];
		await nodesRequest.getAllNodesFromPg();
		nodesRequest.pgAllNodes.forEach((node) => {
			nodes.push({
				label: node.nodeUID,
				value: node.externalId,
				customer: node.customerName,
			});
		});

		let types = [];
		const typeResponse = await nodesRequest.fetchDeviceType();
		typeResponse.forEach((type) => {
			types.push({
				label: type,
				value: type,
			});
		});
		setState({
			nodes: nodes,
			customers: customers,
			types: types,
		});
	};

	const updateSelectedDevice = async (selectedNode) => {
		if (!selectedNode.length) {
			resetForm();
			return;
		}
		const selectedCustomer = selectedNode[0].customer;
		if (selectedCustomer !== UNASSIGNED_CUSTOMER) {
			let customers = [];
			await nodesRequest.getCustomersListFromPg();
			nodesRequest.pgCustomers.forEach((customer) => {
				if (customer.name === UNASSIGNED_CUSTOMER || customer.name === selectedNode[0].customer) {
					customers.push({
						label: customer.name,
						value: customer.externalId,
					});
				}
			});
			setState({
				customers: customers,
			});
		}

		setState({
			selectedNode: selectedNode,
			loadingNodes: true,
			filterBy: t('filter_by') + ': ' + selectedCustomer,
		});

		let typeText = '';
		let newNodeList = [];
		nodesRequest.pgAllNodes.forEach((node) => {
			if (node.customerName === selectedCustomer) {
				newNodeList.push({
					label: node.nodeUID,
					value: node.externalId,
					customer: node.customerName,
				});
			}
		});

		form.customers.forEach((customer) => {
			if (customer.label === selectedCustomer) {
				setState({
					selectedCustomer: customer,
				});
			}
		});

		let definedType = {};
		let newTypes = form.typeIndicatorList;
		if (selectedNode.length === 1) {
			const type = await nodesRequest.fetchDeviceType([selectedNode[selectedNode.length - 1].label]);
			definedType = {
				label: type[0],
				value: type[0],
			};
			newTypes.push(type[0]);
		} else {
			let devices = [];
			selectedNode.forEach((node) => {
				devices.push(node.label);
			});

			const deviceTypesRes = await nodesRequest.fetchDeviceType(devices);
			let resultSet = deviceTypesRes.reduce(function (acc, curr) {
				acc[curr] ? acc[curr]++ : acc[curr] = 1;
				return acc;
			}, {});
			if (Object.keys(resultSet).length === 1) {
				definedType = {
					label: deviceTypesRes[0],
					value: deviceTypesRes[0],
				};
			} else {
				const typePlaceholder = 'type (quantity) ';
				for (const obj in resultSet) {
					typeText = typeText + typePlaceholder.replace('type', obj).replace('quantity', resultSet[obj]);
				}
				definedType = {
					label: 'Mixed',
					value: 'Mixed',
				};
			}
			newTypes = deviceTypesRes;
		}

		setState({
			nodes: newNodeList,
			isCustomerDisabled: false,
			isTypeDisabled: false,
			selectedType: definedType,
			loadingNodes: false,
			typeIndicator: typeText,
			typeIndicatorList: newTypes,
		});
	};


	const updateSelectedCustomer = (selectedCustomer) => {
		setInputChanged(true);
		setState({
			selectedCustomer: selectedCustomer,
		});
	};

	const updateSelectedType = (selectedType) => {
		setInputChanged(true);
		setState({
			selectedType: selectedType,
		});
	};


	const adjustControls = async () => {
		if (form.filterBy.includes(UNASSIGNED_CUSTOMER)) {
			if (form.selectedCustomer.label !== UNASSIGNED_CUSTOMER && await checkTypeChanged()) {
				updateState('green', t('add_devices_and_change'), false, false, false);
			} else if (form.selectedCustomer.label !== UNASSIGNED_CUSTOMER) {
				updateState('green', t('add_devices'), false, false, false);
			} else if (await checkTypeChanged()) {
				updateState('blue', t('change_type'), false, false, false);
			} else {
				updateState('', '', true, false, false);
			}
		} else {
			if (form.selectedCustomer.label === UNASSIGNED_CUSTOMER) {
				updateState('red', t('remove_devices'), false, false, true);
			} else if (await checkTypeChanged()) {
				updateState('blue', t('change_type'), false, true, false);
			} else {
				updateState('', '', true, false, false);
			}
		}
	};

	const updateState = (messageClass, messageText, submit, customer, type) => {
		setState({
			isTypeDisabled: type,
			isCustomerDisabled: customer,
			isSubmitDisabled: submit,
			messageClass: messageClass,
			operationMsg: messageText,
		});
	};

	const checkTypeChanged = async () => {
		if (form.selectedNode.length > 1) {
			if (form.selectedType.label === 'Mixed') return false;
			if (form.typeIndicator !== '') return true;
		}
		const type = await nodesRequest.fetchDeviceType([form.selectedNode[0].label]);
		return type[0] !== form.selectedType.label;
	};

	const resetForm = () => {
		setState(initialState);
		populateFormControls();
	};

	const submitForm = async (e) => {
		e.preventDefault();
		if (form.messageClass === 'red') {
			setState({
				showConfirmation: true,
				operationMsg: t('remove_devices_confirmation'),
			});
		} else {
			const nodeExternalIds = [];
			form.selectedNode.map((node) => nodeExternalIds.push(node.value));
			let status = '';
			switch (form.operationMsg) {
			case t('add_devices'):
				status = DEVICE_MANAGEMENT.ON_BOARD;
				break;
			case t('change_type'):
				status = DEVICE_MANAGEMENT.CHANGE;
				break;
			case t('add_devices_and_change'):
				status = DEVICE_MANAGEMENT.ON_BOARD_AND_CHANGE;
				break;
			default:
				// Do nothing
			}
			const response = await nodesRequest.updateDeviceAssignment(nodeExternalIds,
				form.selectedCustomer.value,
				form.selectedType.label,
				status);
			if (Object.keys(response).length === 0) {
				store.dispatch(updateDeviceHistory(true));
				await resetForm();
			} else {
				// Show failed EUI
				alert('Failed euis: ' + response);
			}
		}
	};

	const offBoardDevice = async (e) => {
		e.preventDefault();
		const nodeExternalIds = [];
		form.selectedNode.map((node) => nodeExternalIds.push(node.value));
		const response = await nodesRequest.updateDeviceAssignment(nodeExternalIds,
			form.selectedCustomer.value,
			form.selectedType.label,
			DEVICE_MANAGEMENT.OFF_BOARD);
		if (Object.keys(response).length === 0) {
			store.dispatch(updateDeviceHistory(true));
			resetForm();
		} else {
			// Show failed EUI
		}
	};

	const cancelConfirmation = () => {
		setState({
			showConfirmation: false,
			operationMsg: t('remove_devices'),
		});
	};

	return (
		<Form className='form__device__management' onSubmit={submitForm}>
			<p className='section__heading'>{t('device_management')}</p>
			<Form.Group controlId='deviceEUI'>
				<Form.Label className='input__label'>{t('device_eui')}</Form.Label>
				<span className='filter__text'>{form.filterBy}</span>
				<Select
					isMulti
					onChange={(value) => updateSelectedDevice(value || [])}
					options={form.nodes}
					isLoading={form.loadingNodes}
					components={{
						IndicatorSeparator: () => null,
						DropdownIndicator: () => null,
					}}
					placeholder={form.nodePlaceholder}
					noOptionsMessage={() => t('not_found')}
					classNamePrefix='form__device__management__input'
					value={form.selectedNode}
				/>
			</Form.Group>
			<Form.Group controlId='customer'>
				<Form.Label className='input__label'>{t('customer')}</Form.Label>
				<Select
					isDisabled={form.isCustomerDisabled}
					onChange={(value) => updateSelectedCustomer(value)}
					options={form.customers}
					components={{
						IndicatorSeparator: () => null,
						DropdownIndicator: () => null,
					}}
					placeholder={form.customerPlaceholder}
					noOptionsMessage={() => t('not_found')}
					classNamePrefix='form__device__management__input'
					value={form.selectedCustomer}
				/>
			</Form.Group>
			<Form.Group controlId='device_type'>
				<Form.Label className='input__label'>{t('device_type')}</Form.Label>
				<Select
					isDisabled={form.isTypeDisabled}
					options={form.types}
					onChange={(value) => updateSelectedType(value)}
					components={{
						IndicatorSeparator: () => null,
						DropdownIndicator: () => null,
					}}
					placeholder={form.typePlaceholder}
					noOptionsMessage={() => t('not_found')}
					classNamePrefix='form__device__management__input'
					value={form.selectedType}
				/>
				<span className='type__indicator'>{form.typeIndicator}</span>
			</Form.Group>
			<div className={'operation__message ' + form.messageClass}>{form.operationMsg}</div>
			{form.showConfirmation
				? <div className='d-flex'>
					<button
						type='button'
						className='action__button confirmation'
						onClick={cancelConfirmation}>
						{t('no')}
					</button>
					<button
						type='submit'
						onClick={offBoardDevice}
						className='action__button confirmation'>
						{t('yes')}
					</button>
				</div>
				: <button
					type='submit'
					disabled={form.isSubmitDisabled}
					className={'action__button submit'}>
					{t('go')}
				</button>}
		</Form>
	);
};

DeviceForm.displayName = 'DeviceForm';

export default DeviceForm;
