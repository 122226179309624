import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import loadingAnimation from '../../assets/loading_animation.svg';
import './LoadingCog.scss';

const LoadingCog = ({ isVisible, width, height, containerWidth, containerHeight }) => {
	const { t } = useTranslation();
	if (isVisible) {
		return (
			<div className='loading d-flex justify-content-center align-items-center' style={{ width: containerWidth, height: containerHeight }}>
				<img src={loadingAnimation} alt={t('loading')} className='loading__animation' style={{ width, height }} />
			</div>
		);
	} else {
		return <></>;
	}
};

LoadingCog.propTypes = {
	isVisible: PropTypes.bool.isRequired,
	width: PropTypes.string,
	height: PropTypes.string,
	containerWidth: PropTypes.string,
	containerHeight: PropTypes.string,
};

export default LoadingCog;