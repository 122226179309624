/**
 * Convert from JSON date format to human-readable format
 * @param {Date} date - Date object
 * @example '1/01/2000 • 12:00:00 AM'
 */
export const formatDate = (date) => {
	return  new Intl.DateTimeFormat('en-US', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	}).format(new Date(date)).replace(',', ' • ');
};

// These constants should be same as constants in api project. Please do not change in one location.
export const DEVICE_MANAGEMENT = {
	ON_BOARD: 'ON_BOARD',
	OFF_BOARD: 'OFF_BOARD',
	CHANGE: 'CHANGE',
	ON_BOARD_AND_CHANGE: 'ON_BOARD_AND_CHANGE',
};
